export default {
    colorParams: {
        'green': {
            type: 1,
            value: 'green',
            label: '绿色'
        },
        'orange': {
            type: 2,
            value: 'orange',
            label: '黄色'
        },
        'blue': {
            type: 3,
            value: 'blue',
            label: '蓝色'
        },
        'lightblue': {
            type: 4,
            value: 'lightblue',
            label: '深蓝'
        },
        'golden': {
            type: 5,
            value: 'golden',
            label: '金色'
        },
        'red': {
            type: 6,
            value: 'red',
            label: '红色'
        },
        'purple': {
            type: 7,
            value: 'purple',
            label: '紫色'
        }
    }
}