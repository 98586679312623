<template>
  <div class="right-customservice" v-show="show" v-if="rightNavData.KefuType === 0 && (rightNavData.KefuQQList.length || rightNavData.KefuTelList.length || rightNavData.UnionWeixin)">
    <div class="rcs-head">
      在线客服
      <img :src="'close_icon.png' | ImgFilter('all')" @click="show = false" class="c-p" id="color-rcs" alt="" />
    </div>
    <div class="rcs-content">
      <template v-if="rightNavData.KefuQQList.length">
        <!-- <div class="rcs-item">在线咨询：</div> -->
        <div class="color-subtext rcs-qq-box">
          <p v-for="(qqKeFu, index) in rightNavData.KefuQQList" :key="index">
            <a :href="'http://wpa.qq.com/msgrd?v=3&amp;uin=' + qqKeFu.KefuNumber + '&amp;site=qq&amp;menu=yes'" target="_blank">
              <img :src="'QQ.png' | ImgFilter('all')" alt="" class="align-middle" />
              {{ qqKeFu.KefuName ? qqKeFu.KefuName : "客服" + kefuNum[index] }}
            </a>
          </p>
        </div>
      </template>
      <template v-if="rightNavData.KefuTelList.length">
        <div class="rcs-item">电话咨询：</div>
        <div class="color-subtext fontsize-13 rcs-phone">
          <div v-for="(phone, index) in rightNavData.KefuTelList" :key="index" class="phone-item">
            <p class="name flex-box">
              <img :src="'dianh.png' | ImgFilter('all')" alt="" class="align-middle mr-5" />
              {{phone.KefuName}}
            </p>
            <p class="phone">{{ phone.KefuNumber }}</p>
          </div>
        </div>
      </template>
      <div class="weixin-cs" v-if="rightNavData.UnionWeixin">
        <img :src="rightNavData.UnionWeixin" alt="" />
      </div>
    </div>
    <div class="rcs-footer c-p" @click="handler2TOP">
      TOP
      <img :src="'top.png' | ImgFilter('all')" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "CustomService",
  data() {
    return {
      show: true,
      rightNavData: {
        KefuCode: "",
        KefuType: 0, //客服类型  0: 电话+QQ  1: CC客服  2:53客服  3:百度商桥  4:乐语客服  5:企业QQ
        KefuQQList: [],
        KefuTelList: [],
        UnionWeixin: "",
      },
      kefuNum: ["①", "②", "③", "④", "⑤", "⑥", "⑦", "⑧", "⑨", "⑩"],
    };
  },
  mounted() {
    this.handlerGetKeFuByHost();
  },
  methods: {
    async handlerGetKeFuByHost() {
      const { Result } = await this.$api.GetKeFuByHost();
      this.rightNavData = Result;

      /**客服类型不为0时动态加载对应的js */
      if (this.rightNavData.KefuType !== 0) {
        this.keFuType(this.rightNavData.KefuType, this.rightNavData.KefuCode);
      } else {
        this.rightNavData.KefuTelList = (this.rightNavData?.KefuTelList || []).filter(item => item.KefuNumber)
        this.rightNavData.KefuQQList = (this.rightNavData?.KefuQQList || []).filter(item => item.KefuNumber)
      }
    },
    /**客服类型 */
    keFuType(type, code) {
      var jsUrl = "";
      switch (type) {
        case 1:
          //CC客服
          jsUrl = `http://kefu.ziyun.com.cn/vclient/?webid=${encodeURIComponent(code)}`;
          break;
        case 2:
          //53客服
          jsUrl = `https://tb.53kf.com/code/code/${code}`;
          break;
        case 3:
          //百度
          jsUrl = `http://hm.baidu.com/hm.js?${code}`;
          break;
        case 4:
          //乐语
          jsUrl = `http://lead.soperson.com/${code}.js`;
          break;
        case 5:
          //企业QQ
          jsUrl = `http://wpa.b.qq.com/cgi/wpa.php?key=${code}`;
          break;
      }
      this.createJs(jsUrl);
    },
    /**动态生成js */
    createJs(url) {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      document.getElementsByTagName("head")[0].appendChild(script);
    },
    handler2TOP() {
      var timer = null;
      cancelAnimationFrame(timer);
      timer = requestAnimationFrame(function fn() {
        var oTop = document.body.scrollTop || document.documentElement.scrollTop;
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop = oTop - 50;
          timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(timer);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.right-customservice {
  width: 120px;
  //   min-height: 299px;
  background: #ffffff;
  border-radius: 6px;
  position: fixed;
  z-index: 1011;
  right: 20px;

  box-shadow: 0px 5px 24px 3px rgba(68, 78, 87, 0.11);
  top: 25%;
  text-align: center;

  .rcs-head {
    text-align: center;
    position: relative;
    font-size: 16px;
    width: 120px;
    height: 43px;
    line-height: 43px;
    @include background_color_jb(90deg, "color1", "color1_1");
    border-radius: 6px 6px 0 0;
    color: #fff;
    img {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 9px;
      height: 9px;
    }
  }
  .rcs-footer {
    width: 120px;
    height: 39px;
    line-height: 35px;
    background: #f9f8f8;
    border-radius: 0 0 6px 6px;
    img {
      width: 13px;
      height: 9px;
    }
  }
  .rcs-content {
    padding: 0 12px;
    font-size: 14px;
    .rcs-item {
      border-bottom: 1px solid #f4f4f4;
      font-weight: bold;
      height: 42px;
      line-height: 52px;
      img {
        width: 20px;
        height: 20px;
        margin-bottom: 5px;
      }
    }
    .rcs-phone {
      padding: 12px 0 15px 0;
      .phone-item {
        margin-bottom: 10px;
        .phone {
          color: #999;
        }
      }
    }
    .rcs-qq-box {
      padding: 12px 0 0 0;
      p {
        margin-bottom: 12px;
      }
    }
    .weixin-cs {
      img {
        max-width: 83px;
        max-height: 83px;
      }
      padding-bottom: 15px;
    }
  }
}
</style>
