import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    common
  },
  plugins: [createPersistedState({
    storage: window.localStorage,
    reducer(val) {
      return {
        colorType: val.common.colorType
      }
    }
  })]
})
