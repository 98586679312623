import service from "../utils/request"
import serviceJava from "../utils/requestJava"

export default {
    GetCookies: (params) => service.get('/unioninfo/promote/GetCookies', { params }),// 获取cookies 1
    GetSeniorFlag: (params) => service.get('/unioninfo/info/GetSeniorFlag', { params }),// 获取是否高级平台 1
    GetLoginStatus: (params) => service.get('/unioninfo/info/GetLoginStatus', { params }),// 获取登录状态 1
    GetUnionLogoByHost: (params) => service.get('/unioninfo/info/GetUnionLogoByHost', { params }),// 获取logo 1
    // GetPhoneByHost: (params) => service.get('/unioninfo/info/GetPhoneByHost', { params }),// 获取顶部电话 1
    GetHeaderByHost: (params) => service.get('/unioninfo/info/GetHeaderByHost', { params }),// links 1
    GetBannerByHost: (params) => service.get('/unioninfo/info/GetBannerByHost', { params }),// banner 1
    GetOrderCountAndCouponCount: (params) => service.get('/unioninfo/info/GetOrderCountAndCouponCount', { params }),// 1
    GetUnionClass: (params) => service.get('/course/class/GetUnionClass', { params }),// 联盟考试 1
    // GetHomePageCourseByClassID: (params) => service.get('/course/course/GetHomePageCourseByClassID', { params }),// 课程推荐
    // GetExaminationCountDown: (params) => service.get('/Course/Category/GetExaminationCountDown', { params }),// 获取考试倒计时
    // GetClassTopThreeByHost: (params) => service.get('/Course/Class/GetClassTopThreeByHost', { params }),// 热门课程
    GetAppQrcode: (params) => service.get('/unioninfo/info/GetAppQrcode', { params }),// 二维码 x
    getlive: (params) => service.get('/live/live/getlive', { params }),// 直播 1
    // GetHomePageGetCouponByHost: (params) => service.get('/unioninfo/info/GetHomePageGetCouponByHost', { params }),// 隔断处理 1有优惠券未登录 2有优惠券登录 3开通扫码领课且没有优惠券 4 隐藏
    GetTeacherListByHost: (params) => service.get('/unioninfo/info/GetTeacherListByHost', { params }),// 网校老师
    GetSkinColorByHost: (params) => service.get('/unioninfo/info/GetSkinColorByHost', { params }),// 换肤 1
    GetKeFuByHost: (params) => service.get('/unioninfo/info/GetKeFuByHost', { params }),// 客服 1
    GetFooterByHost: (params) => service.get('/unioninfo/info/GetFooterByHost', { params }),// 获取页尾 1
    Statistics: (params) => service.get('/UnionInfo/Info/Statistics', { params }),// cnzz 1
    GetUnionPermission: (params) => service.get('/unioninfo/info/GetUnionPermission', { params }),// 优惠券数量 1
    GetUnionBkzn: (params) => service.get('/unioninfo/BkznPrescription/GetUnionBkzn', { params }),// 报考指南 
    /**新增接口 */
    GetHomePageTop3CourseByClassID: (params) => service.get('/Course/Course/GetHomePageTop3CourseByClassID', {params}), // PC 首页推荐课程(前面3个)
    GetTeacherListByClassId: (params) => service.get('/Course/Course/GetTeacherListByClassId', {params}), // 获取老师介绍(最多返回3个)
    GetBaseCourseByClassId: (params) => service.get('/Course/Course/GetBaseCourseByClassId', {params}), // 如果该分类下面没有新手指南，则去取233分类下的基础班
    GetTeacherTeamInfo: (params) => service.get('/Course/Course/GetTeacherTeamInfo', {params}), // 获取大类id下面的老师
    GetContactNumber: (params) => service.get('/Course/Course/GetContactNumber', {params}),  // 获取联系方式
    GetUnionPermissionYhq: (params) => service.get('/Course/Course/GetUnionPermission', {params}),  // 对学员开放优惠券
    

    getLiveReport: (params) => serviceJava.post(`/ess-live-api/unionLive/do/preview`, params), // 获取直播列表
}