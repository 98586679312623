<template>
  <div class="">
    <header class="header">
      <div class="head-continer clearfix">
        <div class="left">
          <div class="fl left-nav">
            <a href="javascript:void(0)">
              <img class="logo" :src="logoObj.logo" alt="logo" v-if="logoObj.type === 1" />
              <h1 v-else>{{ logoObj.logo }}</h1>
            </a>
          </div>
          <div class="fl left-nav">
            <a href="/#/" title="首页">首页</a>
          </div>
          <div class="fl left-nav" v-for="item in chains" :key="item.ID">
            <a target="_blank" :href="item.LinkUrl" :title="item.Title">{{ item.Title.slice(0, 6) }}</a>
          </div>
          <!-- <div class="fl left-nav" id="choice-course">
            <a href="/usercenter/#/" target="_blank" title="课程">课程</a>
          </div>
          <div class="fl left-nav">
            <a href="/tiku/exam/chapter" target="_blank" title="题库">题库</a>
          </div>
          <div class="fl left-nav">
            <a href="/m/zhibo/#/" target="_blank" title="题库">直播</a>
          </div>
          <div class="fl left-nav">
            <a href="/course/taste" target="_blank" title="体验中心">体验中心</a>
          </div>
          <div class="fl left-nav">
            <a href="/search/app" target="_blank" title="APP下载">APP下载</a>
          </div> -->
        </div>
        <!-- <div class="right">
          <div class="fl">
            <a href="/uc/userinfo" title="学习中心" onclick="handlerCheckLogin()">学习中心</a>
          </div>
          <div class="fl">
            <img class="avator-img" :src="loginStatus.picPath || ('//img.233.com/union/union_pc/union-temphone/user-icon-default.png')" alt="" />
          </div>
          <div class="fl" id="login-box" v-if="!loginStatus.status">
            <a href="javascript:;" @click="openLink(1)">
              <span class="login color-primary">登录</span>
            </a>
            <label></label>
            <a href="javascript:;" @click="openLink(2)">
              <span class="register">注册</span>
            </a>
          </div>
          <div id="head-nick-box" class="fl" v-else>
            <span class="nick-name" id="nick-name">{{ loginStatus.userName }}</span>
          </div>
        </div> -->
      </div>
    </header>
    <!-- <div class="fix-header"></div> -->
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {},
  data() {
    return {
      logoObj: {},
      chains: [],
    };
  },
  computed: {
    loginStatus() {
      return this.$store.state.common.loginStatus;
    },
    colorParams() {
      return this.$store.state.common.colorParams;
    },
  },
  created() {
    this.handlerQueryLogo();
    this.handlerGetHeaderByHost()
  },
  methods: {
    async handlerGetHeaderByHost() {
      const {Result} = await this.$api.GetHeaderByHost({})
      this.chains = Result
    },
    async handlerQueryLogo() {
      const { Result } = await this.$api.GetUnionLogoByHost({});
      this.logoObj = Result;
    },
    openLink(type) {
      switch (type) {
        case 1:
          window.location.href = window.location.origin + "/uc/login?redirecturl=" + window.location.href;
          break;
        case 2:
          window.open(window.location.origin + "/uc/register");
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// .fix-header {
//   height: 78px;
// }
.header {
  font-size: 16px;
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  z-index: 999;
  height: 78px;
  background: #ffffff;
  width: 100%;
  border-bottom: 1px solid #eeeeee;

  .head-continer {
    overflow: hidden;
    @include pageSize;
    margin: 0 auto;
    height: 100%;

    .left {
      line-height: 78px;
      float: left;
      img {
        vertical-align: middle;
      }
      @media screen and (min-width: 1680px) {
        .left-nav {
          margin-right: 48px;
        }
      }
      .left-nav {
        position: relative;
        margin-right: 32px;
        .logo {
          height: 64px;
        }
        a:hover {
          @include font_color("color1");
        }
      }
    }

    .right {
      a:hover {
        @include font_color("color1");
      }
      #login-box {
        label {
          display: inline-block;
          width: 1px;
          height: 20px;
          background: #eeeeee;
          margin: 0 10px;
          vertical-align: middle;
        }
      }
      img {
        vertical-align: middle;
      }
      line-height: 78px;
      float: right;
      .avator-img {
        margin: 0 19px;
        width: 45px;
        height: 45px;
        border-radius: 50%;
      }
      .nick-name {
        position: relative;
        line-height: 20px;
        padding-right: 12px;
        // &:hover {
        //   @include font_color('color1')
        // }
      }
    }
  }
}
</style>
