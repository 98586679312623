import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from "./api"
import "./utils/permission"
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { getImg, getImgStyle } from "@/utils"
Vue.use(VueAwesomeSwiper)
// import ElementUI from 'element-ui'

import * as filters from '@/utils/filters'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

// Vue.use(ElementUI)
Vue.prototype.$api = api
Vue.prototype.$getImg = getImg
Vue.prototype.$getImgStyle = getImgStyle
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
