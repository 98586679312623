<template>
  <div class="footer">
    <div class="content flex">
      <div class="left-box mr-20 flex-box-center">
        <img :src="'kef_js.png' | imgFilter" alt="" />
        <div class="mt-10 f-16 mb-10 linkus">联系我们</div>
        <div class="fw-600 f-16" style="white-space: nowrap">{{phone}}</div>
      </div>
      <div class="flex right-box">
        <p v-if="menu.length > 0" class="menu">
          <template v-for="(item, index) in menu">
            <span :key="index" @click="openLink(item.LinkUrl)">{{ item.Contents }}</span>
            <label v-if="index + 1 < menu.length" :key="index + 100"></label>
          </template>
        </p>
        <p>
          <template v-if="hotLink.Contents">
            <span>服务热线：{{ hotLink.Contents }}</span>
            <label></label>
          </template>
          <template v-if="qq.Contents">
            <span>QQ：{{ qq.Contents }}</span>
            <label></label>
          </template>
          <template v-if="email.Contents">
            <span>邮箱：{{ email.Contents }}</span>
            <label></label>
          </template>
          <template v-if="address.Contents">
            <span>地址：{{ address.Contents }}</span>
          </template>
        </p>
        <p>
          <span @click="openLink(putonrecord.LinkUrl)">{{ putonrecord.Contents }}</span>
          <template v-if="putonrecord.Contents && gonganbeian.Contents">
            <label></label>
          </template>
          <img v-if="gonganbeian.Contents" src="https://img2.233.com/wx/union/pc/home/bottom_ic.png" />
          <span @click="openLink(gonganbeian.LinkUrl)">{{ gonganbeian.Contents }}</span>
        </p>
        <p>
          <span @click="openLink(gongshang.LinkUrl)">{{ gongshang.Contents }}</span>
          <template v-if="gongshang.Contents && copyright.Contents">
            <label></label>
          </template>
          <span @click="openLink(copyright.LinkUrl)">{{ copyright.Contents }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      footerList: [],
      phone: null
    };
  },
  computed: {
    hotLink() {
      return this.footerList.find((item) => item.Type === "phone") || {};
    },
    qq() {
      return this.footerList.find((item) => item.Type === "qq") || {};
    },
    email() {
      return this.footerList.find((item) => item.Type === "email") || {};
    },
    address() {
      return this.footerList.find((item) => item.Type === "address") || {};
    },
    copyright() {
      return this.footerList.find((item) => item.Type === "copyright") || {};
    },
    putonrecord() {
      return this.footerList.find((item) => item.Type === "putonrecord") || {};
    },
    gonganbeian() {
      return this.footerList.find((item) => item.Type === "gonganbeian") || {};
    },
    menu() {
      return this.footerList.filter((item) => item.Type === "menu") || [];
    },
    gongshang() {
      return this.footerList.find((item) => item.Type === "gongshang") || {};
    },
  },
  created() {
    this.handlerSetStatistics();
    this.handlerGetfooterList();
    this.handlerGetPhoneByHost();
  },
  methods: {
    async handlerGetPhoneByHost() {
      const { Result } = await this.$api.GetContactNumber({});
      this.phone = Result;
    },
    async handlerGetfooterList() {
      const { Result } = await this.$api.GetFooterByHost({});
      this.footerList = Result;
    },
    openLink(v) {
      window.open(v ? v : "https://beian.miit.gov.cn/");
    },
    async handlerSetStatistics() {
      const _script2 = document.createElement("script");
      _script2.type = "text/javascript";
      _script2.src = "https://s9.cnzz.com/z_stat.php?id=1278181871&web_id=1278181871";
      document.body.appendChild(_script2);

      const { Result } = await this.$api.Statistics({});
      setUnionCnzzConfig(Result)
    },
  },
};
</script>

<style>
.home .footer .content p span a {
  /* color: #9A9A9A; */
}
</style>

<style lang="scss" scoped>
.footer {
  width: 100%;
  margin-top: 59px;
  text-align: left;
  background: #fff;
  @include border_color("color1");
  border-top: 2px solid #fff;
  .content {
    margin: 0 auto;
    @include pageSize;
    padding: 32px 0;
    .left-box {
      min-width: 230px;
      height: 180px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(215, 215, 215, 0.5);
      flex-direction: column;
      .linkus {
        position: relative;
        &::after {
          left: 70px;
          top: 50%;
          content: " ";
          position: absolute;
          width: 50px;
          height: 1px;
          background: #e3e3e3;
        }
        &::before {
          right: 70px;
          top: 50%;
          content: " ";
          position: absolute;
          width: 50px;
          height: 1px;
          background: #e3e3e3;
        }
      }
      img {
        width: 60px;
        height: 60px;
      }
    }
    .right-box {
      justify-content: center;
      flex-direction: column;
    }
    p {
      line-height: 26px;
      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(4) {
        span {
          cursor: pointer;
        }
      }
      span {
        color: #555555;
        font-size: 14px;
        font-weight: 400;
        vertical-align: middle;
      }
      label {
        display: inline-block;
        width: 1px;
        height: 14px;
        margin: 0 10px;
        vertical-align: middle;
      }
      img {
        margin-right: 6px;
        vertical-align: middle;
      }
    }
    .menu span {
      color: #555555;
      font-size: 16px;
    }
  }
}
</style>
