
import api from "@/api"
import Constant from "@/utils/constant"
import { isH5 } from "@/utils"

export default {
  namespaced: true,
  state: {
    colorType: 'blue',
    seniorFlog: false, // 是否高级机构
    loginStatus: {},
    appQrcodeObj: {},
    examList: [],
    permissionYhq: {} // 是否开放优惠券
  },
  mutations: {
    updateColorType(state, plyload) {
      if (Constant.colorParams[plyload]?.type) {
        state.colorType = plyload
      } else {
        state.colorType = 'blue'
      }
      let type = Constant.colorParams[plyload]?.type || 1
      window.document.documentElement.setAttribute("data-theme", "c" + type);
    }
  },
  actions: {
    async handlerQueryLoginStatus({ commit, state }, payload) {
      if (!Object.keys(state.loginStatus).length) {
        const { Result } = await api.GetLoginStatus()
        state.loginStatus = Result
      }
    },
    // 查询主题色
    async GetSkinColorByHost({ commit, state }, payload) {
      const { Result } = await api.GetSkinColorByHost({})
      commit('updateColorType', Result.color)
    },
    // 查询App二维码
    async GetAppQrcode({ commit, state }, payload) {
      const { Result } = await api.GetAppQrcode({})
      state.appQrcodeObj = Result
    },
    // 查询是否对学员开发优惠券
    async GetUnionPermissionYhq({ commit, state }, payload) {
      const { Result } = await api.GetUnionPermissionYhq({})
      state.permissionYhq = Result
    },
    async handlerGetUnionClass({ commit, state }, payload) {
      if(state.examList.length) return
      const { Result } = await api.GetUnionClass({});
      let _arr = [];
      Result.forEach((element) => {
        element.ParentId === 0 && _arr.push({ ...element, childData: [] });
      });
      _arr.forEach((item) => {
        Result.forEach((element) => {
          item.ClassId === element.ParentId && item.childData.push(element);
        });
      });
      let _arr2 = [];
      _arr.forEach((item) => {
        if (item.childData.length > 0) {
          _arr2.push(item);
        }
      });
      state.examList = _arr2;
    },
    async GetSeniorFlag({ commit, state }, payload) {
      const { Result } = await api.GetSeniorFlag({})
      state.seniorFlog = Result

      if (isH5()) {
        if (Result) {
          window.location.href = window.location.origin + "/m/fine"
        } else {
          window.location.href = window.location.origin + "/m/norm/new/#/"
        }
      } else {
        if (Result) {
          location.href.indexOf('/norm') && (location.href = location.href.replace('/norm', '/fine'))
        } else {
          location.href.indexOf('/fine') && (location.href = location.href.replace('/fine', '/norm'))
        }
      }
    }
  },
  getters: {
    colorV(s) {
      return Constant.colorParams[s.colorType].value
    }
  }
}
