<template>
  <div id="app">
    <Nav/>
    <Header></Header>
    <div class="page-body">
      <router-view />
    </div>
    <Footer></Footer>
    <CustomService></CustomService>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import CustomService from "@/components/CustomService";
import Nav from "@/components/Nav";
import Cookies from "js-cookie";
export default {
  components: {
    Footer,
    Header,
    CustomService,
    Nav
  },
  data() {
    return {};
  },
  mounted() {
    this.handlQueryPromoteInfo();
    this.$store.dispatch("common/GetSkinColorByHost");
    this.$store.dispatch("common/GetSeniorFlag");
    this.$store.dispatch("common/GetAppQrcode");
  },
  methods: {
    async handlQueryPromoteInfo() {
      if (!Cookies.get("PromoteInfo")) {
        const _location_params = window.location.search.split("?")[1];
        let _promotesign = "";
        _location_params &&
          _location_params.split("&").forEach((item) => {
            item.indexOf("promotesign") > -1 && (_promotesign = item.slice(item.indexOf("promotesign") + 12, item.length));
          });
        if (_promotesign) {
          const res = await this.$api.GetCookies({ promotesign: _promotesign });
          const { Result } = res;
          const { StaffName, PromoteId } = Result;
          StaffName && PromoteId && Cookies.set("PromoteInfo", `StaffName=${StaffName}&PromoteId=${PromoteId}`, { expires: 3 });
        }
      }
    },
  },
  computed: {},
};
</script>
<style lang="scss">
@import "./assets/css/common.scss";
#app {
  background: #f4f4f4;
  color: #333;
  width: 100%;
  font-size: 14px;
  .page-body {
    min-height: 100vh;
  }
}
* {
  box-sizing: border-box;
}
</style>
